import {Column} from '@ozark/common/api/Column';
import {Agent, ViewBase} from '@ozark/common';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

export const columns: Column<ViewBase<Agent>>[] = [
  {
    id: 'createdAt',
    label: 'Created At',
    width: 160,
    sortable: true,
    selector: row => (row.createdAt ? format(row.createdAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
    export: row =>
      row.createdAt
        ? format(utcToZonedTime(new Date(row.createdAt.toDate()), 'UTC'), 'MM dd yyyy h:mm a')
        : '',
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: 160,
    sortable: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: 160,
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    width: 200,
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'lastLoginAt',
    label: 'Last Login',
    selector: row => (row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
  },
];
