import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  AgentPortalTokenResult,
  AgentView,
  AsyncState,
  AuthUserClaims,
  Firebase,
  getUnassignedUserTicketPrecalculationsName,
  GroupRole,
  GroupView,
  MerchantView,
  usePrecalculations,
  UserRoles,
} from '@ozark/common';
import {NavMenu, NavMenuLink, NavMenuParentLink} from '@ozark/common/components';
import {NOTIFICATIONS_ROOT} from '@ozark/common/components/NotificationsPage/routes';
import {isAllTicketsViewStorageName} from '@ozark/common/components/Tickets/constants/constants';
import {
  AgentsIcon,
  ApplicationsIcon,
  AuthorizationsIcon,
  BoardedIcon,
  DashboardIcon,
  DepositsIcon,
  Form1099Icon,
  FraudAnalysisIcon,
  IncompleteIcon,
  MerchantsIcon,
  OnlineAppIcon,
  OnlineAppNewIcon,
  OnlineAppPricingIcon,
  PortfolioACHIcon,
  PortfolioPCIIcon,
  PortfolioReservesIcon,
  PortfolioTINIcon,
  RelationshipManagementIcon,
  ReportsIcon,
  ResidualIcon,
  ResourcesIcon,
  StatementIcon,
  StatementsIcon,
  TransactionsPendingIcon,
  UnderwritingIcon,
  UsersIcon,
} from '@ozark/common/icons';
import {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {TICKETS} from '../../../constants/routes';
import {useStore} from '../../../store/helpers';
import {hasResidualsPermissions} from '../../../utils/hasResidualsPermissions';
import {isMerchantManagementAllowed} from '../../../utils/isMerchantManagementAllowed';
import {SelectApplicationDialog} from '../../SelectApplicationDialog/SelectApplicationDialog';

const handleOpenPortal = async () => {
  const getAgentPortalToken = Firebase.functions.httpsCallable('getAgentPortalToken');
  const result: {data: AgentPortalTokenResult} = await getAgentPortalToken({});
  if (!result?.data?.token) {
    return;
  }
  const a = document.createElement('a');
  a.setAttribute(
    'href',
    `${window.location.protocol}//${result.data.appUrl}/portal?token=${result.data.token}`
  );
  a.setAttribute('target', '_blank');
  a.click();
};

const PortalRoles = [UserRoles.agent, UserRoles.merchant];

enum BadgeCounter {
  tickets = 'tickets',
}

export const drawerWidth = 256;

const allowPermissions = (
  claims: AuthUserClaims | null | undefined,
  permittedRoles: UserRoles[],
  condition?: (claims: AuthUserClaims) => boolean
) => {
  if (!claims?.role) return false;
  return (
    permittedRoles.includes(claims.role as UserRoles) && (condition ? condition(claims) : true)
  );
};

const Navigator = ({...other}) => {
  const {authProfile, claims, group, isUserMerchant} = useStore();
  const isMerchant = isUserMerchant();
  const [isApplicationSelectorOpen, setIsApplicationSelectorOpen] = useState(false);
  const history = useHistory();

  const {value: unassignedTicketCount} = usePrecalculations(
    getUnassignedUserTicketPrecalculationsName(authProfile?.data?.id)
  );

  const isItemVisible = (link: NavMenuLink): boolean => {
    return Boolean(authProfile && claims && link.isVisible(link));
  };

  const links = useMemo(() => {
    const handleSubmitNewApplication = () => {
      if (isMerchant) {
        setIsApplicationSelectorOpen(true);
        return;
      }
      handleOpenPortal();
    };

    const handleTicketsClick = () => {
      localStorage.setItem(isAllTicketsViewStorageName, 'true');
      history.push(TICKETS);
    };

    return getLinksConfig(
      claims,
      authProfile?.data,
      handleSubmitNewApplication,
      group,
      handleTicketsClick
    );
  }, [claims, authProfile, isMerchant, group, history]);

  const badgeCounters = {
    [BadgeCounter.tickets]: unassignedTicketCount,
  };

  return (
    <>
      {isMerchant && (
        <SelectApplicationDialog
          open={isApplicationSelectorOpen}
          setOpen={setIsApplicationSelectorOpen}
        />
      )}

      <NavMenu
        links={links}
        isItemVisible={isItemVisible}
        logo={group.data?.logoUrl}
        logoAlt={group.data?.name}
        drawerProps={other}
        badgeCounters={badgeCounters}
      />
    </>
  );
};
const getLinksConfig = (
  claims: AuthUserClaims | null | undefined,
  authProfile: AgentView | MerchantView | undefined,
  handleSubmitNewApplication: () => void,
  group: AsyncState<GroupView>,
  handleTicketsClick: () => void
): NavMenuParentLink[] => [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    route: ROUTES.DASHBOARD,
    isVisible: () => allowPermissions(claims, PortalRoles),
    exact: true,
  },
  {
    name: 'Applications',
    icon: <ApplicationsIcon />,
    route: ROUTES.APPLICATIONS_ALL,
    isVisible: () => allowPermissions(claims, [UserRoles.agent, UserRoles.merchant]),
    children: [
      {
        name: 'All Applications',
        icon: <ApplicationsIcon />,
        route: ROUTES.APPLICATIONS_ALL,
        isVisible: () => allowPermissions(claims, [UserRoles.agent, UserRoles.merchant]),
      },
      {
        name: 'Incomplete',
        icon: <IncompleteIcon />,
        route: ROUTES.APPLICATIONS_INCOMPLETE,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Agent Support',
        icon: <RelationshipManagementIcon />,
        route: ROUTES.APPLICATIONS_AS,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Underwriting',
        icon: <UnderwritingIcon />,
        route: ROUTES.APPLICATIONS_UNDERWRITING,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'C Approved',
        icon: <BoardedIcon />,
        route: ROUTES.APPLICATIONS_CONDITIONALLY_APPROVED,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Boarded',
        icon: <BoardedIcon />,
        route: ROUTES.APPLICATIONS_BOARDED,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Submit New Application',
        icon: <OnlineAppNewIcon />,
        route: 'empty',
        isVisible: () =>
          allowPermissions(claims, [UserRoles.agent, UserRoles.merchant], claims => {
            if (claims.role === UserRoles.merchant) {
              return (
                group.data?.applicationSettings?.enableMerchantsToAddNewAccounts === true &&
                !authProfile?.masterUid
              );
            }
            return true;
          }),
        onClick: handleSubmitNewApplication,
      },
      {
        name: 'Online App URL',
        icon: <OnlineAppNewIcon />,
        route: ROUTES.APP_URL,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
    ],
  },
  {
    name: 'Online App',
    icon: <OnlineAppIcon />,
    route: ROUTES.ONLINE_PRICING,
    isVisible: () =>
      allowPermissions(
        claims,
        [UserRoles.agent],
        claims => claims.groupRole === GroupRole.administrator
      ),
    children: [
      {
        name: 'Pricing',
        icon: <OnlineAppPricingIcon />,
        route: ROUTES.ONLINE_PRICING,
        isVisible: () =>
          allowPermissions(
            claims,
            [UserRoles.agent],
            claims => claims.groupRole === GroupRole.administrator
          ),
      },
    ],
  },
  {
    name: 'Merchant Portfolio',
    icon: <MerchantsIcon />,
    route: ROUTES.MERCHANTS_PORTFOLIO,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
    children: getAnalyticsLinks(claims),
  },
  {
    name: 'MID Reporting',
    icon: <ReportsIcon />,
    route: ROUTES.BATCHES,
    isVisible: () => allowPermissions(claims, PortalRoles),
    children: [
      {
        name: 'Authorizations',
        icon: <AuthorizationsIcon />,
        route: ROUTES.AUTHORIZATIONS,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'Batches',
        icon: <TransactionsPendingIcon />,
        route: ROUTES.BATCHES,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'Deposits',
        icon: <DepositsIcon />,
        route: ROUTES.DEPOSITS,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'Statements',
        icon: <PictureAsPdfIcon />,
        route: ROUTES.STATEMENTS,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'Disputes',
        icon: <StatementIcon />,
        route: ROUTES.CHARGEBACKS_SUMMARY,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'Reserves',
        icon: <PortfolioReservesIcon />,
        route: ROUTES.PORTFOLIO_RESERVES,
        isVisible: () => allowPermissions(claims, [UserRoles.merchant]),
      },
      {
        name: 'ACH Rejects',
        icon: <PortfolioACHIcon />,
        route: ROUTES.PORTFOLIO_ACH_REJECTS,
        // Hide menu option as requested in task LUQ-3020
        isVisible: () => false,
      },
      {
        name: 'PCI',
        icon: <PortfolioPCIIcon />,
        route: ROUTES.PORTFOLIO_PCI,
        isVisible: () => allowPermissions(claims, PortalRoles),
      },
      {
        name: 'TIN',
        icon: <PortfolioTINIcon />,
        route: ROUTES.PORTFOLIO_TIN,
        // Hide menu option as requested in task LUQ-3020
        isVisible: () => false,
      },
      {
        name: '1099K',
        icon: <Form1099Icon />,
        route: ROUTES._1099K,
        // Hide menu option as requested in task LUQ-3020
        isVisible: () => false,
      },
      {
        // duplicated name because different sort order for merchant and agent
        name: 'Reserves',
        icon: <PortfolioReservesIcon />,
        route: ROUTES.PORTFOLIO_RESERVES,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
    ],
  },
  {
    // duplicated name because different default route for merchant and agent
    name: 'Analytics',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_VOLUME_DETAIL,
    isVisible: () => allowPermissions(claims, [UserRoles.merchant]),
    children: getAnalyticsLinks(claims),
  },
  {
    name: 'Residuals',
    icon: <ResidualIcon />,
    route: ROUTES.RESIDUALS_AGENT,
    isVisible: () =>
      allowPermissions(claims, [UserRoles.agent]) && hasResidualsPermissions(authProfile),
    children: [
      {
        name: 'Overview',
        icon: <ResidualIcon />,
        route: ROUTES.RESIDUALS_OVERVIEW,
        isVisible: () =>
          allowPermissions(claims, [UserRoles.agent]) &&
          hasResidualsPermissions(authProfile) &&
          (claims?.isGroupOwner || claims?.groupRole === GroupRole.administrator),
      },
      {
        name: 'Group Residuals',
        icon: <ResidualIcon />,
        route: ROUTES.RESIDUALS_GROUP,
        isVisible: () =>
          allowPermissions(claims, [UserRoles.agent]) &&
          hasResidualsPermissions(authProfile) &&
          claims?.groupRole === GroupRole.administrator,
      },
      {
        name: 'Agent Residuals',
        icon: <ResidualIcon />,
        route: ROUTES.RESIDUALS_AGENT,
        isVisible: () =>
          allowPermissions(claims, [UserRoles.agent]) && hasResidualsPermissions(authProfile),
      },
    ],
  },
  {
    name: 'Tickets',
    icon: <AssignmentTurnedInIcon />,
    route: ROUTES.TICKETS,
    isVisible: () => allowPermissions(claims, [UserRoles.agent, UserRoles.merchant]),
    badgeCounter: BadgeCounter.tickets,
    onClick: handleTicketsClick,
  },
  {
    name: 'Resources',
    icon: <ResourcesIcon />,
    route: ROUTES.RESOURCES,
    isVisible: () => allowPermissions(claims, [UserRoles.agent, UserRoles.merchant]),
  },
  {
    name: 'Users',
    icon: <UsersIcon />,
    route: '',
    redirectToFirstChild: true,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
    children: [
      {
        name: 'Agents',
        icon: <AgentsIcon />,
        route: ROUTES.AGENTS,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Portal Registration',
        icon: <StatementsIcon />,
        route: ROUTES.ACTIVATIONS,
        isVisible: () => allowPermissions(claims, [UserRoles.agent]),
      },
      {
        name: 'Registered Merchants',
        icon: <MerchantsIcon />,
        route: ROUTES.MERCHANTS,
        isVisible: () =>
          allowPermissions(claims, [UserRoles.agent], claims =>
            isMerchantManagementAllowed(claims, authProfile)
          ),
      },
    ],
  },
  {
    name: 'Registered Merchants',
    icon: <MerchantsIcon />,
    route: ROUTES.MERCHANTS,
    isVisible: () =>
      allowPermissions(claims, [UserRoles.merchant], claims =>
        isMerchantManagementAllowed(claims, authProfile)
      ),
  },
  {
    name: 'Notifications',
    icon: <NotificationsIcon />,
    route: NOTIFICATIONS_ROOT,
    isVisible: () => true,
  },
  {
    name: 'Email Log',
    icon: <MailOutlineIcon />,
    route: ROUTES.EMAIL_LOG,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'Referral Bonus',
    icon: <ResidualIcon />,
    route: ROUTES.REFERRAL,
    // Hide menu option as requested in task LUQ-3020
    isVisible: () => false,
  },
];

const getAnalyticsLinks = (claims: AuthUserClaims | null | undefined): NavMenuLink[] => [
  {
    name: 'Merchant Activity',
    icon: <StatementsIcon />,
    route: ROUTES.MERCHANTS_PORTFOLIO,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'Portfolio Statistics',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_PORTFOLIO,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'Agent Statistics',
    route: ROUTES.AGENT_STATISTICS,
    icon: <FraudAnalysisIcon />,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'Monthly Volume Details',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_VOLUME_DETAIL,
    isVisible: () => allowPermissions(claims, PortalRoles),
  },
  {
    name: 'Monthly Authorization Details',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_AUTHORIZATION_DETAIL,
    isVisible: () => allowPermissions(claims, PortalRoles),
  },
  {
    name: 'Agent/MID Association',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_AGENT_MID_ASSIGNMENT,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'Yearly Summary',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_YEARLY_SUMMARY,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'MCCs by Sales',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_MCC_BY_SALES,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'MIDs by Processing',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_MIDS_BY_PROCESSING,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
  {
    name: 'MIDs by Chargebacks',
    icon: <AssessmentIcon color="disabled" />,
    route: ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS,
    isVisible: () => allowPermissions(claims, [UserRoles.agent]),
  },
];

export default Navigator;
